import React from 'react'
import styled from 'styled-components'

import PageWrapper from '../templates/PageWrapper'
import useSiteData from '../hooks/useSiteData'

import { Link } from 'gatsby'
import { Button } from '../components/atoms'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const About = () => {
  const siteMetadata = useSiteData()

  return (
    <PageWrapper title={`About — ${siteMetadata.siteTitle}`}>
      <Wrapper>
        <h1>About Gatsby</h1>
        <p>Such wow. Very React.</p>
        <Link to="/">
          <Button>Return to home</Button>
        </Link>
      </Wrapper>
    </PageWrapper>
  )
}

About.displayName = 'About'

export default About
