import { useStaticQuery, graphql } from 'gatsby'

const useSiteData = () => {
  const { site } = useStaticQuery(graphql`
    query getSiteData {
      site {
        siteMetadata {
          siteTitle
        }
      }
    }
  `)

  return site.siteMetadata
}

export default useSiteData
